import { useCallback } from 'react';

import { notify } from 'src/utils/notify';

const useCopyToClipboard = () => {
	const copy = useCallback(async (text: string) => {
		try {
			await navigator.clipboard.writeText(text);
			notify('Copied to clipboard!');
		} catch {
			notify('Failed to copy', 'error');
		}
	}, []);

	return { copy };
};

export default useCopyToClipboard;
