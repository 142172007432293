import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { BigNumber } from 'ethers';

import { useWeb3Context } from 'src/context/web3';
import { notify } from 'src/utils/notify';
import web3config from 'src/utils/web3config';

import useXellarReducer from '../reducers/useXellarReducer';
import useWeb3Helper from './useWeb3Helper';

const queryGetSubscriptionPrograms = 'queryGetSubscriptionPrograms';

const useWeb3Subscription = () => {
	const { subscriptionAddress, subscriptionAbi } = web3config;

	const {
		canAccessWeb3,
		isXellar,
		getXellarContract,
		getContract,
		address,
		chainId,
		xellarChainId,
	} = useWeb3Context();

	const currentChainId = chainId ?? xellarChainId;

	const { web3Approval } = useWeb3Helper();

	const { xellarSendTransaction, setXellarItemData } = useXellarReducer();

	const web3GetSubscriptionPlan = async () => {
		if (!canAccessWeb3) {
			return false;
		}

		try {
			if (isXellar) {
				const contract = await getXellarContract(subscriptionAbi, subscriptionAddress);
				const subscriptionPrograms = await contract.getPrograms();
				return subscriptionPrograms;
			} else {
				const contract = await getContract(subscriptionAbi, subscriptionAddress);
				const subscriptionPrograms = await contract.methods.getPrograms().call();
				return subscriptionPrograms;
			}
		} catch (err: any) {
			console.log(err);
			// notify(err?.message, 'error');
			return false;
		}
	};

	const web3BuySubscription = async (
		chosenDay: string,
		priceInCreo: string | number | BigNumber,
	) => {
		if (canAccessWeb3) {
			try {
				await web3Approval(subscriptionAddress, priceInCreo);

				if (isXellar) {
					setXellarItemData({ funcName: 'BUY SUBSCRIPTION', price: Number(priceInCreo) });
					const result: any = await xellarSendTransaction(
						subscriptionAddress,
						subscriptionAbi,
						address,
						'buySubscription',
						chosenDay,
					);

					notify('Subscription purchase successful!');
					return result;
				} else {
					const subscriptionContract = await getContract(subscriptionAbi, subscriptionAddress);
					const result = await subscriptionContract.methods
						.buySubscription(chosenDay)
						.send({ from: address });

					notify('Subscription purchase successful!');
					return result;
				}
			} catch (err: any) {
				notify(err?.message, 'error');
				return false;
			}
		}
		return false;
	};

	const timeout = (ms: number) => {
		const message =
			'Subscription operation timed out. Check your internet and try again. VPN may help.';

		return new Promise((_, reject) => setTimeout(() => reject(new Error(message)), ms));
	};

	const web3CheckSubscription = async () => {
		if (currentChainId?.toString() !== '56') return false;

		if (!canAccessWeb3) {
			return false;
		}

		try {
			let result;
			if (isXellar) {
				const contract = await getXellarContract(subscriptionAbi, subscriptionAddress);

				result = await Promise.race([contract.subscriptions(address), timeout(6000)]);
			} else {
				const contract = await getContract(subscriptionAbi, subscriptionAddress);

				result = await Promise.race([
					contract.methods.subscriptions(address).call(),
					timeout(6000),
				]);
			}

			if (!result) {
				return false;
			}

			const end = dayjs.unix(result.endDate);
			const now = dayjs();
			const diff = end.diff(now);

			return {
				endDate: result.endDate,
				isActive: result.isActive && !isNaN(diff) && diff > 0,
			};
		} catch (err: any) {
			// notify(err?.message, 'error');
			notify('Failed to load subscription data. Please check your network and try again.', 'error');
			return false;
		}
	};

	return { web3BuySubscription, web3GetSubscriptionPlan, web3CheckSubscription };
};

export const useGetSubscriptionPrograms = () => {
	const { getContract } = useWeb3Context();

	const { web3GetSubscriptionPlan } = useWeb3Subscription();
	return useQuery(
		[queryGetSubscriptionPrograms],
		() => {
			return web3GetSubscriptionPlan();
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!getContract },
	);
};

export default useWeb3Subscription;
