import ImageC from '../Image';

import classes from './index.module.less';

const CardAuth = ({
	showCreoplayImage = true,
	children,
}: {
	showCreoplayImage?: boolean;
	children: any;
}) => {
	return (
		<div className={classes.card}>
			{showCreoplayImage && (
				<ImageC
					alt="logo creoplay"
					className={classes.logo}
					src="/icons/creoplay-with-text.svg"
					width={131}
					height={32}
				/>
			)}
			{children}
		</div>
	);
};

export default CardAuth;
