import { useEffect, useRef, useState } from 'react';

import classes from './index.module.less';

interface Props {
	otp?: any[];
	setOtp?: (otp: any[]) => void;
}

const InputOTP = ({ otp: propsOtp, setOtp: setPropsOtp }: Props) => {
	const otpNumberOfDigits = 6;

	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));

	const otpBoxRef = useRef<any[]>([]);

	useEffect(() => {
		setTimeout(() => {
			(document.querySelectorAll(`.${classes['input-otp']}`)?.[0] as any)?.focus();
		}, 100);
	}, []);

	useEffect(() => {
		if (JSON.stringify(otp) !== JSON.stringify(propsOtp)) {
			setPropsOtp?.(otp);
		}
	}, [otp]);

	useEffect(() => {
		if (propsOtp && JSON.stringify(otp) !== JSON.stringify(propsOtp)) {
			setOtp(propsOtp);
		}
	}, [propsOtp]);

	const handleOtpChange = (value: number, index: number) => {
		let newArr = [...otp];
		newArr[index] = value;
		setOtp(newArr);

		if (value && index < otpNumberOfDigits - 1) {
			otpBoxRef.current[index + 1].focus();
		}
	};

	const handleOtpBackspaceAndEnter = (e: any, index: number) => {
		if (e.key === 'Backspace' && !e.target.value && index > 0) {
			otpBoxRef.current[index - 1].focus();
		}
		if (e.key === 'Enter' && e.target.value && index < otpNumberOfDigits - 1) {
			otpBoxRef.current[index + 1].focus();
		}
	};

	return (
		<div className={classes['row-input-otp']}>
			{otp.map((digit, index) => (
				<div key={index} className={classes['col-input-otp']}>
					<div className={classes['wrapper-input']}>
						<input
							className={classes['input-otp']}
							ref={(reference: any) => (otpBoxRef.current[index] = reference)}
							value={digit}
							min={0}
							max={9}
							maxLength={1}
							onChange={(e: any) => handleOtpChange(e.target.value, index)}
							onKeyUp={(e: any) => handleOtpBackspaceAndEnter(e, index)}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default InputOTP;
