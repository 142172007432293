import { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	className?: string;
	size?: number;
	label?: ReactNode;
	checked?: boolean;
	checkedIcon?: 'fill' | 'check';
	onChange?: (e: any) => void;
}

const Checkbox = ({
	className = '',
	size = 30,
	label = '',
	checkedIcon = 'fill',
	...props
}: Props) => {
	const checboxRef = useRef<any>(null);
	const [checked, setChecked] = useState<boolean>(false);

	const handleChange = (e: any) => {
		if (props?.onChange) {
			props.onChange(e);
		} else {
			setChecked(e.target.checked);
		}
	};

	useEffect(() => {
		if (props?.checked !== undefined && props?.checked !== checked) {
			setChecked(props?.checked);
		}
	}, [props?.checked]);

	return (
		<div
			className={classNames(classes['checkbox-container'], className)}
			onClick={() => checboxRef?.current?.click()}
		>
			<input
				className="d-none"
				ref={checboxRef}
				type="checkbox"
				checked={checked}
				onChange={handleChange}
				onClick={(e) => e.stopPropagation()}
			/>
			<div
				className={classNames(classes.checkbox, {
					[classes.checked]: checked,
					[classes['use-icon-check']]: checkedIcon === 'check',
				})}
				style={{ minHeight: size, minWidth: size, height: size, width: size }}
			/>
			<div className={classes.label}>{label}</div>
		</div>
	);
};

export default Checkbox;
