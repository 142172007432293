import { useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useRequestResetPassword } from 'src/hooks';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import Input from '../Input';

import classes from './index.module.less';

interface Props {
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardForgotPassword = ({}: Props) => {
	const [step, setStep] = useState<number>(1);
	const [formData, setFormData] = useState<any>(null);
	const formRef = useRef(null);

	const { mutate: requestResetPassword, isLoading } = useRequestResetPassword();

	const isButtonLoginDisabled =
		formData == null ||
		Object.values(formData ?? [])?.find((val: any) => val.length < 3) !== undefined;

	const handleFormChange = () => {
		const newFormData = new FormData(formRef.current as any);
		const formProps = Object.fromEntries(newFormData);

		setFormData(formProps);
	};

	const handleResetClick = (e: any) => {
		e.preventDefault();

		requestResetPassword(
			{
				email: formData.email,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200 && res?.data?.data) {
						setStep(2);
					} else {
						notify(res?.data?.message ?? 'Failed to request reset password', 'error');
					}
				},
			},
		);
	};

	return (
		<CardAuth>
			{step === 1 && (
				<>
					<h1 className={classes.title}>Reset Your Password</h1>
					<form
						ref={formRef}
						className={classes.form}
						onChange={handleFormChange}
						onSubmit={handleResetClick}
					>
						<Row gutter={[30, 12]}>
							<Col span={24}>
								<Input type="email" name="email" placeholder="Email" />
							</Col>
							<Col span={24}>
								<Button
									className="w-100"
									type="submit"
									loading={isLoading}
									disabled={isButtonLoginDisabled}
								>
									Send Request
								</Button>
							</Col>
						</Row>
					</form>
				</>
			)}
			{step === 2 && (
				<>
					<h1 className={classes.title}>Check your email</h1>
					<div className={classNames(classes.label, 'mt-5')}>
						<div>
							We will send a reset link to your email
							<br />
							<span className="fw-500">{formData.email}</span>
						</div>
					</div>
				</>
			)}
		</CardAuth>
	);
};

export default CardForgotPassword;
