import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useAddEmailAndPassword, useResendRegisterOtp, useVerifyRegisterOtp } from 'src/hooks';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import ImageC from '../Image';
import Input from '../Input';
import InputOTP from '../InputOTP';

import classes from './index.module.less';

interface Props {
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardConnectEmail = ({ onClose, onSuccess, updateLoadingState }: Props) => {
	const { clearUserProfile } = useAuthReducer();

	const { mutate: addEmailAndPassword, isLoading: isLoadingAddEmailAndPassword } =
		useAddEmailAndPassword();
	const { mutate: resendOtp } = useResendRegisterOtp();
	const { mutate: verifyOtp, isLoading: isLoadingVerifyOtp } = useVerifyRegisterOtp();

	const otpNumberOfDigits = 6;
	const otpExpiryTime = parseInt(process.env.NEXT_PUBLIC_OTP_EXPIRED_DURATION as string); // in seconds

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [clipboardText, setClipboardText] = useState<string>('');
	const [formData, setFormData] = useState<{
		email: string;
		password: string;
		password_confirmation: string;
	}>({
		email: '',
		password: '',
		password_confirmation: '',
	});
	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));
	const [otpExpiredCountdown, setOtpExpiredCountdown] = useState<number>(0);

	const finalOtp = otp.join('');

	const otpExpiredCountdownRef = useRef(otpExpiryTime);

	let otpExpiredCountdownInterval: any = null;
	const stopOtpExpiredCountdown = () => {
		if (otpExpiredCountdownInterval !== null) {
			clearInterval(otpExpiredCountdownInterval);
			otpExpiredCountdownInterval = null;
		}
	};
	const startOtpExpiredCountdown = () => {
		stopOtpExpiredCountdown();

		otpExpiredCountdownRef.current = otpExpiryTime;
		setOtpExpiredCountdown(otpExpiredCountdownRef.current);

		otpExpiredCountdownInterval = setInterval(() => {
			if (otpExpiredCountdownRef.current === 0) {
				stopOtpExpiredCountdown();
				return;
			}
			otpExpiredCountdownRef.current = otpExpiredCountdownRef.current - 1;
			setOtpExpiredCountdown(otpExpiredCountdownRef.current);
		}, 1000);
	};

	const getCountdownTimer = () => {
		const min: string = Math.floor(otpExpiredCountdown / 60).toString();
		let sec: string = (otpExpiredCountdown % 60).toString();
		if (sec.length === 1) {
			sec = '0' + sec;
		}
		return min + ':' + sec;
	};

	const getClipboardText = async () => {
		let text = (await navigator?.clipboard?.readText?.()) ?? '';
		text = text.replace(/\s/g, '');
		setClipboardText(text);
	};

	let getClipboardTextInterval: any = null;
	const startGetClipboardTextInterval = () => {
		getClipboardText();

		if (getClipboardTextInterval === null) {
			getClipboardTextInterval = setInterval(() => {
				getClipboardText();
			}, 1000);
		}
	};
	const stopGetClipboardTextInterval = () => {
		clearInterval(getClipboardTextInterval);
		getClipboardTextInterval = null;
	};

	const validatePassword = () => {
		const password = formData.password;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumbers = /\d/.test(password);
		// const hasSpecialCharacters = /[!@#\$%\^&\*]/.test(password);
		return password.length >= 8 && (hasUpperCase || hasLowerCase) && hasNumbers;
	};

	const validateRePassword = () => {
		return formData.password === formData.password_confirmation;
	};

	const validateFormData = (data: any) => {
		if (
			data == null ||
			Object.values(data ?? [])?.find((val: any) => val.length < 3) !== undefined
		) {
			return false;
		}

		return true;
	};

	const handleFormInputChange = (e: any) => {
		const name = e.target.name;
		let value = e.target.value;

		setFormData({ ...formData, [name]: value });
	};

	const handlePasteFromClickboardClick = () => {
		setOtp([
			clipboardText[0] ?? '',
			clipboardText[1] ?? '',
			clipboardText[2] ?? '',
			clipboardText[3] ?? '',
			clipboardText[4] ?? '',
			clipboardText[5] ?? '',
		]);
	};

	const handleRegisterClick = (e: any) => {
		e.preventDefault();

		if (!validatePassword()) {
			return notify('Use 8 or more characters mixing letters and numbers', 'error');
		} else if (!validateRePassword()) {
			return notify('Please re-enter password correctly', 'error');
		}

		addEmailAndPassword(
			{
				email: formData.email,
				password: formData.password,
				password_confirmation: formData.password_confirmation,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						startOtpExpiredCountdown();
						setStep(2);
					} else {
						notify(
							res?.data?.data?.error ??
								res?.data?.message ??
								'Failed to register email and password',
							'error',
						);
					}
				},
			},
		);
	};

	const handleResendOtpClick = () => {
		resendOtp(
			{ email: formData.email },
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						startOtpExpiredCountdown();
					} else {
						notify(res?.data?.message ?? 'Failed to resend otp', 'error');
					}
				},
			},
		);
	};

	const handleVerifyOtpClick = (e: any) => {
		e.preventDefault();

		if (finalOtp.length === otpNumberOfDigits) {
			verifyOtp(
				{ email: formData.email, otp: finalOtp },
				{
					onSuccess: (res: any) => {
						if (res.status === 200) {
							clearUserProfile();

							notify(
								res?.data?.message ??
									// eslint-disable-next-line max-len
									'Email and password registered successfully. You can now login with your new email and password.',
							);

							onSuccess?.();
						} else {
							notify(res?.data?.message ?? 'Failed to verify otp', 'error');
						}
					},
				},
			);
		}
	};

	useEffect(() => {
		const isLoading = isLoadingAddEmailAndPassword || isLoadingVerifyOtp;
		updateLoadingState?.(isLoading);
	}, [isLoadingAddEmailAndPassword, isLoadingVerifyOtp]);

	useEffect(() => {
		startGetClipboardTextInterval();
		return () => {
			stopGetClipboardTextInterval();
		};
	}, []);

	useEffect(() => {
		setButtonDisabled(true);
		setOtp(new Array(otpNumberOfDigits).fill(''));
	}, [step]);

	useEffect(() => {
		if (validateFormData(formData)) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [formData]);

	useEffect(() => {
		if (finalOtp.length === otpNumberOfDigits) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [finalOtp]);

	return (
		<CardAuth>
			<div className={classes.header}>
				<div>
					{step > 1 && (
						<ImageC
							alt="icon back"
							className="cursor-pointer"
							src="/icons/icon-back-white.svg"
							width={16}
							height={16}
							onClick={() => setStep(step - 1)}
						/>
					)}
				</div>
				<div>
					<ImageC
						alt="icon close"
						className="cursor-pointer"
						src="/icons/icon-close-white.svg"
						width={16}
						height={16}
						onClick={onClose}
					/>
				</div>
			</div>

			{step === 1 && <h1 className={classes.title}>Connect your email</h1>}
			{step === 2 && <h1 className={classes.title}>Enter Code</h1>}

			{step === 1 && (
				<form className={classes.form} onSubmit={handleRegisterClick}>
					<Row gutter={[30, 12]}>
						<Col span={24}>
							<Input
								type="email"
								name="email"
								placeholder="Email"
								value={formData.email}
								onChange={handleFormInputChange}
							/>
						</Col>
						<Col span={24}>
							<Input
								type="password"
								name="password"
								placeholder="Password"
								autoComplete="new-password"
								value={formData.password}
								onChange={handleFormInputChange}
							/>
						</Col>
						<Col span={24}>
							<Input
								type="password"
								name="password_confirmation"
								placeholder="Re-enter Password"
								value={formData.password_confirmation}
								onChange={handleFormInputChange}
							/>
						</Col>
						{/* <Col span={24}>
							<div className="fs-10 fw-400 text-center mt-5" style={{ color: '#CCC' }}>
								Lorem ipsum dolor sit amet consectetur. Nam ut aliquam risus a
							</div>
						</Col> */}
						<Col span={24}>
							<Button
								className={classNames('w-100', classes['submit-btn'])}
								type="submit"
								loading={isLoadingAddEmailAndPassword}
								disabled={buttonDisabled}
							>
								Connect
							</Button>
						</Col>
					</Row>
				</form>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'])}
					onSubmit={handleVerifyOtpClick}
				>
					<InputOTP otp={otp} setOtp={setOtp} />

					<div className={classNames(classes.label, 'mt-4')}>
						<div>
							A one time verification code has been sent to{' '}
							<span className="fw-500">{formData.email}</span>
						</div>
					</div>
					{finalOtp.length === otpNumberOfDigits ? (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							type="submit"
							loading={isLoadingVerifyOtp}
							disabled={buttonDisabled}
						>
							Verify
						</Button>
					) : (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							onClick={handlePasteFromClickboardClick}
							disabled={clipboardText.length === 0}
						>
							Paste from Clipboard
						</Button>
					)}

					<div className={classNames(classes.label, 'fw-400')}>
						<div>
							Expires in <span className="fw-500">{getCountdownTimer()}</span>
						</div>
						<a
							className={classNames({ disabled: otpExpiredCountdown !== 0 })}
							onClick={handleResendOtpClick}
						>
							Resend code
						</a>
					</div>
				</form>
			)}
		</CardAuth>
	);
};

export default CardConnectEmail;
